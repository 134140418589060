import { withTranslation, Link } from 'server/i18n'
import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import StarIcon from 'public/icons/star.svg'
import style from './PageTitle.style'

const PageTitleStyled = styled.div(props => ({ ...style(props) }))

const PageTitle = ({
    t,
    title,
    subtitle,
    lead,
    titleTag: TitleTag,
    subtitleTag: SubtitleTag,
    leadTag: LeadTag,
    subtitleOnClick,
    isOnListing,
    hasWhatsNew,
    isBlogPost,
    isHomepage
}) => {
    return (
        <PageTitleStyled
            isOnListing={isOnListing}
            hasWhatsNew={hasWhatsNew}
            isBlogPost={isBlogPost}
            isHomepage={isHomepage}>
            <div className="TitleWrap">
                {title && <TitleTag className="title" dangerouslySetInnerHTML={{ __html: title }} />}
                {subtitle && (
                    <SubtitleTag
                        className="subtitle"
                        dangerouslySetInnerHTML={{ __html: subtitle }}
                        onClick={subtitleOnClick}
                    />
                )}
                {lead && <LeadTag className="lead" dangerouslySetInnerHTML={{ __html: lead }} />}
            </div>

            {hasWhatsNew && (
                <Link href="/specials">
                    <a className="WhatsNewButton">
                        <StarIcon /> {t('whats-new')}?
                    </a>
                </Link>
            )}
        </PageTitleStyled>
    )
}

PageTitle.propTypes = {
    t: PropTypes.func.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    lead: PropTypes.string,
    titleTag: PropTypes.string,
    subtitleTag: PropTypes.string,
    leadTag: PropTypes.string,
    subtitleOnClick: PropTypes.func,
    isOnListing: PropTypes.bool,
    hasWhatsNew: PropTypes.bool,
    isBlogPost: PropTypes.bool,
    isHomepage: PropTypes.bool
}

PageTitle.defaultProps = {
    title: '',
    subtitle: undefined,
    lead: undefined,
    titleTag: 'h2',
    subtitleTag: 'h3',
    leadTag: 'p',
    subtitleOnClick: () => {},
    isOnListing: false,
    hasWhatsNew: false,
    isBlogPost: false,
    isHomepage: false
}

export default withTranslation('common')(withTheme(PageTitle))
